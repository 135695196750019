let curLevel = 0
export function useHasClassNameInParent(target, className, level = 5) {
  ++curLevel
  if (curLevel > level) {
    curLevel = 0
    return false
  }
  // 检查当前节点是否包含指定的类名
  if (target.classList.contains(className)) {
    curLevel = 0
    return true
  }
  // 如果当前节点是document，则结束递归
  if (target === document.documentElement) {
    curLevel = 0
    return false
  }
  // 否则，继续向上查找父节点
  return useHasClassNameInParent(target.parentNode, className,level)
}
