<template>
  <div class="project-swiper" :class="{ 'mobile-terminal': isMobile }">
    <swiper
      :key="swiperKey"
      loop
      :modules="[Virtual]"
      :spaceBetween="0"
      :slidesPerView="1"
      :slidesPerGroup="1"
      :centeredSlides="false"
      :resistance="true"
      :resistanceRatio="0.85"
      virtual
      @swiper="onSwiper"
    >
      <swiper-slide
        v-for="group in groupedData"
        :key="group.id"
        :class="{
          'grid-repeat-2': gridRepeat === '2'
        }"
      >
        <v-card
          v-for="(item, j) in group"
          :key="j"
          color="#fff"
          height="174"
          class="swiper-slide-item"
        >
          <div class="rating-container">
            <v-rating
              :model-value="item.score"
              readonly
              :size="22"
              half-increments
              color="yellow-darken-3"
            ></v-rating>
          </div>
          <div class="evaluate-text custom-scrollbar">
            {{ item.content }}
          </div>
          <div class="evaluater-contanier">
            <span
              v-if="getScreenWidth() >= 1280"
              :title="item.reviewer?.toLocaleUpperCase()"
              class="evaluater"
              >{{ (item.reviewer || "").toLocaleUpperCase() }}</span
            >
            <span v-else class="evaluater">{{
              (item.reviewer || "").toLocaleUpperCase()
            }}</span>
          </div>

          <div class="card-bottom">
            <span class="evaluate-time">{{ item.reviewDate }}</span>
          </div>
        </v-card>
      </swiper-slide>
    </swiper>
    <div class="button-prev btn" @click.stop="prevEl" v-if="isOneScreen">
      <img :src="next" />
    </div>
    <div class="button-next btn" @click.stop="nextEl" v-if="isOneScreen">
      <img :src="next" />
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, watch } from "vue"
import { Swiper, SwiperSlide } from "swiper/vue"
import { Virtual } from "swiper/modules"
import next from "@/assets/svg/next-dark.svg"
import "swiper/css"
import { useGlobalStore } from "~/stores"

const { $Reporter } = useNuxtApp()

const props = defineProps({
  dataList: {
    type: Array,
    default: () => []
  }
})

watch(
  () => props.dataList,
  (newData) => {
    if (newData && newData.length) {
      groupDataList()
    }
  }
)

const swiperKey = ref(0)

let slidesPerView = ref(6) // 每屏显示的滑块数量

const isOneScreen = ref(true) // 是否是一屏

let useSwiper = ref(null) // swiper 实例

const groupedData = ref([]) // 转换后的二维数组

const isMobile = ref(false)

const gridRepeat = ref("")

// 设置当前显示的评价个数
const setslidesPerViewFn = (num) => {
  slidesPerView.value = num
  if (props?.dataList?.length <= num) isOneScreen.value = false
}

//获取当前屏幕宽度
const getScreenWidth = () => {
  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  )
}

// 根据不同屏幕获取当前展示的评价个数
const updateSwiperSettings = () => {
  swiperKey.value += 1
  isOneScreen.value = true
  const screenWidth = getScreenWidth()
  if (screenWidth >= 1280) {
    isMobile.value = false
    gridRepeat.value = ""
  } else if (screenWidth >= 640 && screenWidth < 1280) {
    isMobile.value = false
    gridRepeat.value = "2"
  } else if (screenWidth < 640) {
    isMobile.value = true
    gridRepeat.value = "1"
  }
  switch (true) {
    case screenWidth >= 1280:
      setslidesPerViewFn(6)
      break
    case screenWidth >= 640:
      setslidesPerViewFn(2)
      break
    default:
      setslidesPerViewFn(1)
  }
  groupDataList()
}

// 处理获取到的数据转为二维数组
const groupDataList = () => {
  const dataList = props.dataList
  const perPage = slidesPerView.value
  const newGroupedData = []
  for (let i = 0; i < dataList.length; i += perPage) {
    newGroupedData.push(dataList.slice(i, i + perPage))
  }
  groupedData.value = newGroupedData
}

// 初始化swiper
const onSwiper = (swiper) => {
  useSwiper.value = swiper
}

// 上一下
const prevEl = () => {
  $Reporter.click({ event: "e_home_evaluate_toggle" })
  useSwiper.value && useSwiper.value.slidePrev()
}

// 下一页
const nextEl = () => {
  $Reporter.click({ event: "e_home_evaluate_toggle" })
  useSwiper.value && useSwiper.value.slideNext()
}

// 初始化时和窗口大小改变时更新
onMounted(() => {
  window.addEventListener("resize", updateSwiperSettings)
  updateSwiperSettings()
})
// 注销方法
onUnmounted(() => {
  window.removeEventListener("resize", updateSwiperSettings)
})
</script>
<style scoped lang="scss">
.project-swiper {
  position: relative;
  .swiper {
    /* max-width: 1040px; */
    :deep(.swiper-wrapper) {
      margin-left: -100%;
    }
  }
  :deep(.swiper-slide) {
    width: 100% !important; /* 确保滑块宽度适配屏幕 */
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    justify-content: center;
    align-items: center;
    gap: 8px;
    .swiper-slide-item {
      width: 100%;
      height: 244px !important;
      box-sizing: border-box;
      box-shadow: unset;
      border-radius: 0;
      .rating-container {
        display: flex;
        height: 58px;
        justify-content: center;
        align-items: center;
      }
      .evaluater-contanier {
        display: flex;
        justify-content: flex-end;
        padding-right: 24px;
        margin-top: 12px;
        .evaluater {
          height: 24px;
          max-width: calc(100% - 54px);
          font-size: 18px;
          font-weight: 600;
          text-decoration: underline;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
        }
      }
      .evaluate-text {
        height: calc(100% - 58px - 24px - 42px - 12px);
        padding: 0px 10px 0 24px;
        margin-right: 6px;
        line-height: 21px;
        font-size: 16px;
        overflow-y: auto;
        touch-action: pan-y; /* 允许垂直滚动 */
        color: #000000e6;
      }
      .card-bottom {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 24px;
        .evaluate-time {
          line-height: 21px;
          color: #333;
          font-size: 16px;
          font-weight: 600;
          margin-top: 6px;
        }
      }
      &:hover {
        color: #fff;
        background-color: #00bbef !important;
        .evaluater {
          color: #fff;
        }
        .v-rating__item {
          .text-yellow-darken-3 {
            color: #fff !important;
          }
        }
        .evaluate-text {
          color: #fff;
        }
        .evaluate-time {
          color: #fff;
        }
      }
    }
    &.grid-repeat-2 {
      grid-template-columns: repeat(2, 1fr);
      .swiper-slide-item {
        &:hover {
          color: #000000e6 !important;
          background-color: #ffffff !important;
          .evaluater {
            color: #000;
          }
          .v-rating__item {
            .text-yellow-darken-3 {
              color: #f9a825 !important;
            }
          }
          .evaluate-text {
            color: #000000e6 !important;
          }
          .evaluate-time {
            color: #333 !important;
          }
        }
      }
    }
  }
  .btn {
    position: absolute;
    top: 50%;
    width: 48px;
    cursor: pointer;
    border-radius: 5px;
    line-height: 50px;
    text-align: center;
    margin-top: -25px;
    z-index: 9;
    img {
      width: 100%;
    }
  }
  .button-prev {
    transform: rotateY(180deg);
    left: -100px;
  }
  .button-next {
    right: -100px;
  }
}

.mobile-terminal {
  .swiper {
    width: 100%;
  }
  :deep(.swiper-slide) {
    overflow: hidden;
    justify-content: flex-start;
    grid-template-columns: repeat(1, 1fr);
    .swiper-slide-item {
      padding: 0 12px;
      height: unset !important;
      .rating-container {
        height: 48px;
      }
      .evaluater-contanier {
        padding-right: 12px;
        .evaluater {
          height: 18px;
          font-size: 12px;
        }
      }
      .evaluate-text {
        height: 57px;
        font-size: 13px;
        line-height: 14px;
        margin-right: 0px;
        padding-left: 0;
      }
      .card-bottom {
        padding-right: 12px;
        .evaluate-time {
          margin-top: 0;
          margin-bottom: 6px;
          font-size: 12px;
          color: #333;
        }
      }
      &:hover {
        color: #000000e6 !important;
        background-color: #ffffff !important;
        .evaluater {
          color: #000;
        }
        .v-rating__item {
          .text-yellow-darken-3 {
            color: #f9a825 !important;
          }
        }
        .evaluate-text {
          color: #000000e6 !important;
        }
        .evaluate-time {
          color: #333 !important;
        }
      }
    }
  }
  .btn {
    width: 32px !important;
  }
  .button-prev {
    left: -32px !important;
  }
  .button-next {
    right: -32px !important;
  }
}
</style>
