<template>
  <div class="rp-home-page">
    <!-- banner -->
    <!-- 骨架屏,没数据骨架屏和banner区域都不显示 -->
    <shopmall-skeleton class="banner d-flex" :class="{ 'mobile-terminal': !gStore.isPc }"  v-if="!getImgListSuccess && imgList.length <= 0" />
    <div class="banner d-flex" v-if="gStore.isPc && getImgListSuccess && imgList.length > 0">
      <v-carousel
        cycle
        hide-delimiter-background
        :show-arrows="imgList.length > 1 ? 'hover' : false"
        :hide-delimiters="imgList.length <= 1"
        :interval="delayTime"
        v-model="carouselModel"
        @click="carouselUpdate"
      >
        <v-carousel-item
          :selected-class="item.linkUrl && gStore.isPc ? 'cursor-pointer' : ''"
          v-for="item in imgList"
          :key="item.id"
          @click="jumpFn(item.linkUrl)"
          cover
        >
          <img reporter-module="home_banner" reporter-appear reporter-appear-duration :src="item.imageUrl" class="w-100 h-100" />
        </v-carousel-item>
      </v-carousel>
    </div>
    <div class="banner" :class="{ 'mobile-terminal': !gStore.isPc }" v-if="!gStore.isPc && getImgListSuccess && imgList.length > 0">
      <shopmall-home-banner-mobile :imgList="imgList" @jumpFn="jumpFn"/>
    </div>

    <!-- top categories -->
    <!-- 骨架屏,没数据骨架屏和top categories区域都不显示 -->
    <div class="top-categories" :class="{ 'mobile-terminal': !gStore.isPc }"  v-if="!getCategoryListSuccess && categories.length <= 0">
      <div class="top-categories-title">
        <span>TOP</span> SELLING CATEGORIES
      </div>
      <div class="top-categories-contanier exposure_home_top_selling">
          <shopmall-skeleton
            v-for="i in 6"
            :key="i"
            :width="gStore.isPc ? '400px' : '45vw'"
            :height="gStore.isPc ? '431px' : 45 * 1.1 + 'vw'"
          />
      </div>
    </div>
    <div reporter-module="home_top_selling_categories" reporter-appear reporter-appear-duration class="top-categories" :class="{ 'mobile-terminal': !gStore.isPc }" v-if="getCategoryListSuccess && categories.length > 0">
      <div class="top-categories-title">
        <span>TOP</span> SELLING CATEGORIES
      </div>
      <div class="top-categories-contanier exposure_home_top_selling">
        <div
          class="top-categories-item"
          v-for="(item, index) in categories"
          :key="index"
          @click="jumpCategory(item)"
        >
          <img :src="item.imageUrl" class="w-100" />
          <div class="categories-more">MORE</div>
        </div>
      </div>
    </div>

    <!-- 产品推荐 -->
    <div class="High-value-products">
      <shopmall-home-high-view-products />
    </div>

    <!-- 印刷图片 -->
    <div reporter-module="home_print_image exposure_home_print_image" reporter-appear reporter-appear-duration  class="print-image" :class="{ 'mobile-terminal': !gStore.isPc }">
      <img :src="printImage" />
    </div>

    <!-- 客户评价 -->
    <div
      reporter-module="home_comment"
      reporter-appear
      reporter-appear-duration
      class="customers-saying"
      :class="{ 'mobile-terminal': !gStore.isPc }"
      v-if="evaluateList.length > 0"
    >
      <div class="customers-saying-title">
        WHAT OUR CUSTOMERS ARE <span>SAYING</span>
      </div>
      <div class="customers-saying-contanier exposure_home_comment">
        <shopmall-home-evaluate :dataList="evaluateList" />
      </div>
    </div>
  </div>
</template>

<script setup>
import './css/home.scss'
import { systemInteractor, customerInteractor } from "~/core"
import { onMounted, ref, watch } from "vue"
import { useGlobalStore } from "~/stores";
import { handleUrlNavigation } from '~/utils'

const { $Utils, $Buryingpoint, $BuryIntersectionObserver, $Reporter } = useNuxtApp()

const gStore = useGlobalStore()

watch(
  () => gStore.isPc,
  (v) => {
    getPrintImage()
  }
)

const delayTime = ref(5000)

const carouselModel = ref(0)

// 获取轮播图数据
const imgList = ref([])
const getImgListSuccess = ref(false)
const getImgList = async () => {
  const res = await useLocalCachedApi(
    "imgList",
    systemInteractor.listByTenantIdImgApi
  )
  if (res) {
    getImgListSuccess.value = true
    imgList.value = res.map((item) => {
      return {
        ...item,
        imageUrl: useWebp(item[gStore.isPc ? "imageUrl" : "imageMobUrl"])
      }
    })
  }else {
    getImgListSuccess.value = false
  }

  nextTick(() => {
    // 轮播图曝光
    new $BuryIntersectionObserver(".v-window-item");
  })
}

const carouselUpdate = (e) => {
  const isTarget =  useHasClassNameInParent(e.target,'v-window__right') || useHasClassNameInParent(e.target,'v-window__left')
  isTarget && $Reporter.click({event: 'e_home_banner_toggle'})
}

// 点击轮播跳转
const jumpFn = (url) => {
  if (!url) return
  const querys = [
    {key: 'source', value: 'new_home'},
    {key: 't', value: 'ba'},
  ]
  handleUrlNavigation(url, querys)
  $Reporter.click({event: 'e_home_banner_click'})
  $Buryingpoint.bannerClick(url)
}

// Top Categories
const categories = ref([])
const getCategoryListSuccess = ref(false)
const getCategoryList = async () => {
  const res = await useLocalCachedApi(
    "categories_new",
    systemInteractor.listByTenantIdApi,
    6
  )
  if (res) {
    getCategoryListSuccess.value = true
    categories.value = res.map((item) => {
      return {
        ...item,
        imageUrl: useWebp(item.imageUrl)
      }
    })
  }else {
    getCategoryListSuccess.value = false
  }

  nextTick(() => {
    new $BuryIntersectionObserver(".exposure_home_top_selling");
  })
}

// 分类跳转
const jumpCategory = (item) => {
  navigateTo(
    `/category/${$Utils.replaceSpacesWithDash(item.categoryNameEn, false)}?source=new_home`
  )
}

// 获取印刷图片
const printImage = ref("")
const getPrintImage = async () => {
  const [res] = await systemInteractor.getPrintImageApi(gStore.isPc ? 1 : 2)
  if (res) {
    printImage.value = useWebp(res[0]) || ""
  } else {
    printImage.value = ""
  }

  nextTick(() => {
    new $BuryIntersectionObserver(".exposure_home_print_image");
  })
}

//评价
const evaluateList = ref([])
const getevaluateList = async () => {
  const res = await useLocalCachedApi(
    "evaluateList",
    customerInteractor.listHomeReviewsApi
  )
  if (res) {
    evaluateList.value = res
  }

  nextTick(() => {
    new $BuryIntersectionObserver(".exposure_home_comment");
  })
}

onMounted(() => {
  getImgList()
  getCategoryList()
  getPrintImage()
  getevaluateList()

  $Buryingpoint.commonClick('new_version_pv')
})
</script>
